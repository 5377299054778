import React, {useContext, useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import moment from 'moment';
import {Context as TermsContext} from 'common/store/terms';
import ColoredScrollbars from 'components/scrollbar/scrollbar';
import {ReactComponent as LogoImage} from "assets/img/app/deepsea-logo.svg";
import {ReactComponent as CloseIcon} from 'assets/img/app/global-imgs/close.svg';
import {ReactComponent as TermsErrorIcon} from 'assets/img/app/global-imgs/termsError.svg';

const TermsModal = () => {
    let anchors = {};
    const [termsDisplay, setTermsDisplay] = useState('current');

    const {
        state: {currentTerms, futureTerms, hasReceivedTerms, loadTerms, hasNewTerms, modalShow, errorServer, errorCurrent, errorNew},
        termsFlow, showTermsModal
    } = useContext(TermsContext);

    //set react refs for scrolling on clicks!
    (currentTerms.data.length > futureTerms.data.length ? currentTerms.data : futureTerms.data)
        .forEach((section, i) => {anchors[`anchor${i}`] = React.createRef();});

    useEffect(() => {
        if(modalShow) termsFlow({hasReceivedTerms});
    // eslint-disable-next-line
    }, [modalShow]);

    const renderError = (text) => <div className="flex-centered-col marg-t-40 terms-error section-title">
        <TermsErrorIcon className="svg-path marg-b-40" />
        {text}
    </div>;

    return (
        <Dialog open={modalShow} onClose={() => showTermsModal(false)} aria-labelledby="terms-dialog-title" className="centered-modal terms-dialog">
            <DialogTitle id="terms-dialog-title">
                <LogoImage className="modal-terms-logo svg-g-path" />
                <div className="close-button" onClick={() => showTermsModal(false)}>
                    <div className="close-button__img">
                        <CloseIcon className="svg-defs-path-g-maskUse" />
                    </div>
                </div>
            </DialogTitle>
            {
                errorServer !== false || (errorCurrent !== false && errorNew !== false)
                ? <DialogContent>
                    <div className="flex-centered-col">
                        <div className="terms-header marg-b-10">Terms & Conditions</div>
                        {renderError(errorServer)}
                    </div>
                </DialogContent>
                : loadTerms &&
                <DialogContent>
                    <div className="flex-centered-col">
                        <div className="terms-header marg-b-10">Terms & Conditions</div>
                        <div className="center-text terms-subHeader">ANNEX 1</div>
                        <div className="center-text terms-subHeader marg-b-10">
                            GENERAL TERMS & CONDITIONS (GTC) FOR A CLOUD SERVICE SUBSCRIPTION AGREEMENT
                        </div>
                        <div className="center-text terms-text">
                            {
                                hasNewTerms ?
                                    <React.Fragment>
                                        <span className={`terms-version${termsDisplay === 'future' ? ' active' : ''}`}
                                              onClick={() => setTermsDisplay('future')}
                                        >
                                            Updated Version effective from {moment(futureTerms.date).utc().format('MMMM DD, YYYY')}
                                        </span>
                                        <span className="terms-separator"></span>
                                        <span className={`terms-version${termsDisplay === 'current' ? ' active' : ''}`}
                                              onClick={() => setTermsDisplay('current')}
                                        >
                                            Current Version
                                        </span>
                                    </React.Fragment> :
                                    `Effective from ${moment(currentTerms.date).utc().format('MMMM DD, YYYY')}`
                            }
                        </div>
                    </div>
                    {errorCurrent !== false && termsDisplay === 'current' && renderError(errorCurrent)}
                    {errorNew !== false && termsDisplay === 'future' && renderError(errorNew)}
                    <div className="flex-centered-start marg-t-40">
                        {((errorCurrent === false && termsDisplay === 'current') || (errorNew === false && termsDisplay === 'future')) &&
                            <React.Fragment>
                                <ColoredScrollbars className="anchor-area">
                                    {(termsDisplay === 'current' ? currentTerms.data : futureTerms.data)
                                        .map((section, i) => {
                                        return <Anchor
                                            key={`anchor${i}`}
                                            reference={anchors[`anchor${i}`]}
                                            title={section.title}
                                            indicator={section.indicator}
                                        />;
                                    })}
                                </ColoredScrollbars>
                                <ColoredScrollbars className="scrollable-area">
                                    {(termsDisplay === 'current' ? currentTerms.data : futureTerms.data)
                                        .map((section, i) => {
                                        return <AnchorTarget
                                            key={`anchorTarget${i}`}
                                            reference={anchors[`anchor${i}`]}
                                            title={section.title}
                                            indicator={section.indicator}
                                            paragraphs={section.paragraphs || []}
                                            text={section.text || ''}
                                        />;
                                    })}
                                </ColoredScrollbars>
                            </React.Fragment>
                        }
                    </div>
                </DialogContent>
            }
        </Dialog>
    )
};

const Anchor = ({reference, title, indicator}) => {
    return <div className="terms-anchor flex-start marg-b-10" onClick={() => reference.current.scrollIntoView()}>
        <div className="marg-r-5">{indicator}</div>
        <div>{title}</div>
    </div>;
};

const AnchorTarget = ({reference, text, paragraphs, title, indicator}) => {
    let props = {className: "marg-b-40"};
    !(reference === undefined) && (props.ref = reference);

    const renderParagraphs = (paragraphs) => {
        return paragraphs.map(paragraph => {
            return <div key={`paragraph${paragraph.indicator}`}>
                <div className="flex-start marg-b-10">
                    <div className="terms-paragraph paragraph-indicator">{paragraph.indicator}</div>
                    <div className="terms-text paragraph-text">{paragraph.content}</div>
                </div>
                <div className="marg-b-10 marg-l-50">
                    {'paragraphs' in paragraph && renderParagraphs(paragraph.paragraphs)}
                </div>
            </div>;
        });
    };

    return (
        <div {...props}>
            {!(title === undefined) && <div className="terms-title flex-start marg-b-30">
                <div className="marg-r-5">{indicator}</div>
                <div>{title}</div>
            </div>}
            {text !== '' && <div className="flex-start marg-b-10">
                <div className="terms-text paragraph-text">{text}</div>
            </div>}
            {renderParagraphs(paragraphs)}
        </div>
    );
};

export default TermsModal;