import React from 'react';
import Storage from "common/js/Storage";
import { Urls } from 'common/js/urls';


const Product = props => {
    
    const getProductImage = () => {
        const ImgComp = props.imgs[props.name];
        return <ImgComp className="marg-r-16" />
    };

    const productHandler = () => {
        if(props.disabled) return;
        Storage.addCookie('product', props.name);
        window.location.replace(Urls.BaseUrl());
    }

    return (
        <div className={`product-box flex-centered ${props.disabled ? 'disabled' : ''}`} onClick={productHandler} >
            <div>
                <div className="flex-centered-start product-box__title-section">
                    <div className="product-box__title-section__img">{getProductImage()}</div>
                    <div className={`product-box__title-section__title ${props.name}`}>{props.name.toLowerCase()}</div>
                </div>
                <div className="product-box__description marg-l-42">{props.texts[props.name]}</div>
            </div>
        </div>
    );
}
 
export default Product;