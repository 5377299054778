import React, {useState, useEffect, useContext, useRef} from 'react';
import { texts } from "common/js/login";
import service from 'common/js/service';
import { TextField, InputAdornment  } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Context as TermsContext} from 'common/store/terms';
import Storage from "common/js/Storage";
import { userUtils } from 'common/store/user';
import {ReactComponent as Logo} from '../assets/img/app/login/logo.svg';
import {ReactComponent as ErrorTriangle} from '../assets/img/app/login/error_triangle.svg';
import {ReactComponent as GreyX} from '../assets/img/app/login/GreyX.svg';
import {ReactComponent as GreenTick} from '../assets/img/app/login/GreenTik.svg';
import {ReactComponent as PassEye} from '../assets/img/app/login/passEye.svg';
import {ReactComponent as PassEyeClosed} from '../assets/img/app/login/closedEye.svg';
import MailSent from '../assets/img/app/login/mail-sent-x2.png';
import {ReactComponent as ExpiredLinkIcon} from '../assets/img/app/login/expired_link.svg';
import {ReactComponent as TimeoutLinkIcon} from '../assets/img/app/login/timeout_link.svg';
import {Urls} from 'common/js/urls';

const textsErrors = {
    mail: '', 
    pass1: '', 
    invalidChars: '', 
    pass2: '',
    confirmPass: '',
    terms: ''
}

const initErrorTexts = () => {
    textsErrors.mail = texts.login.mailErrorText;
    textsErrors.pass1 = texts.resetPass.passErrorText;
    textsErrors.invalidChars = texts.resetPass.invalidCharsErrorText;
    textsErrors.pass2 = texts.resetPass.confirmPassErrorText;
    textsErrors.confirmPass = texts.resetPass.confirmPass;
    textsErrors.terms = texts.userActivation.terms;
}

initErrorTexts();

let ConfirmationCode = null, ConfirmationMail = null, activateOrReset = null, _productToRedirect = null;

const LoginPage = () => {
    const chars8 = useRef();
    const symbol1 = useRef();
    const number1 = useRef();
    const latin1 = useRef();

    const [canRenderPage, setCanRenderPage] = useState(false);
    const {showTermsModal} = useContext(TermsContext);
    const [title, setTitle] = useState(texts.login.title);
    const [subtitle, setSubtitle] = useState(texts.login.subtitle);
    const [buttonText, setButtonText] = useState(texts.login.button);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');
    const [errorStatuses, setErrorStatuses] = useState({formError: false, mail: false, pass1: false, pass1ErrorSymbol: false, pass2: false, confirmPass: false, terms: false});
    const [showPass, setShowPass] = useState({pass: false, pass1: false, pass2: false});
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [emptyPage, setEmptyPage] = useState(false);
    const [emptyFields, setEmptyFields] = useState(true);
    const [expiredLinkStatus, setExpiredLinkStatus] = useState(null);
    const [pageStatus, setPageStatus] = useState('login');     // enum: ['login', 'forgotPass', 'resetPassRequest', 'resetPass', 'userActivation']
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const updateErrorStatuses = (key, value) => {
        setErrorStatuses((errorStatuses) => { return {...errorStatuses, [key]: value} });
    }

    const checkForErrors = () => {
        (errorStatuses.formError || errorStatuses.mail || errorStatuses.pass1 || errorStatuses.pass1ErrorSymbol || errorStatuses.pass2 || errorStatuses.confirmPass || errorStatuses.terms)
        ? setButtonDisabled(true)
        : setButtonDisabled(false);
    }

    useEffect(()=>{
        checkForErrors();
    // eslint-disable-next-line
    }, [errorStatuses.formError, errorStatuses.mail, errorStatuses.pass1, errorStatuses.pass1ErrorSymbol, errorStatuses.pass2]);

    useEffect(() => {
        if (termsAccepted && errorStatuses.terms) updateErrorStatuses('terms', false);
    // eslint-disable-next-line
    }, [termsAccepted]);

    const redirectToLogin = () => {
        window.location.replace(Urls.LoginUrl());
    }

    const setFormTexts = type => {
        setTitle(texts[type].title);
        setSubtitle(texts[type].subtitle);
        setButtonText(texts[type].button);
        setPageStatus(type);
        updateErrorStatuses('formError', false);
        updateErrorStatuses('mail', false);
    }

    const setPageWithExpiredMinMessage = () => {
        setEmptyPage(false);
        setExpiredLinkStatus(null);
        setPageStatus('expiredLink');
        setTitle(texts['userActivation'].expiredTitle);
    }

    const setPageWithExpiredMaxMessage = () => {
        setEmptyPage(false);
        setExpiredLinkStatus('disabled');
        setPageStatus('expiredLink');
    }

    const setPageWithTimeoutMessage = () => {
        setEmptyPage(false);
        setExpiredLinkStatus('timeout');
        setPageStatus('expiredLink');
        setTitle(texts['userActivation'].timeoutTitle);
    }

    const codeConfirmationCall = async () => {
        const response = await service.codeConfirmation({username: ConfirmationMail, confirmationCode: ConfirmationCode});
        
        if(response.apiError?.status === 410) {
            const data = response.data === "" ? null : JSON.parse(response.data);
            //when link is more than 24 hours and less than month
            if(data?.link === 'enabled') setPageWithExpiredMinMessage();
            //when link is more than month
            else if(data?.link === 'disabled') setPageWithExpiredMaxMessage();
            //when no valid link
            else setPageWithTimeoutMessage();
        //when idp is down or timeout
        } else if (response.apiError?.status === 408) setPageWithTimeoutMessage();
        //when bad request or invalid confirmation code
        else if (response.apiError?.status === 400) redirectToLogin();
        else if(response.status === 500 || response.error) return setPageWithTimeoutMessage();
        //when status is 200
        else if (response.data?.username) {
            setEmptyPage(false);
            activateOrReset === 'activate' ? setFormTexts('userActivation') : setFormTexts('resetPass');
        //whatever else server error
        }
        else redirectToLogin();
    }

    const checkIfUserIsLoggedin = product => {
        const userIsLoggedIn = Storage.isTokenValid(Storage.getCookie('expiredDate'));

        if( userIsLoggedIn ) {
            if( !product ) {
                window.location.pathname = '/selectProduct';
                return true;
            } 
            window.location.replace(Urls.BaseUrl());
            return true;
        }

        return false;
    }
 
    useEffect(() => {
        const product = Storage.getCookie('product');

        if ( window.location.pathname.includes('activate') || window.location.pathname.includes('reset/password')) {
            window.location.pathname.includes('activate') ? activateOrReset = 'activate' : activateOrReset = 'reset';
            setEmptyPage(true);
            const pathNameArr = window.location.href.split('?')[1].split('&');
            ConfirmationMail = pathNameArr[1].split('=')[1].replace("%40", "@");
            ConfirmationCode = pathNameArr[0].split('=')[1];
            setCanRenderPage(true);
            codeConfirmationCall();
        } else {
            //check if user is already login and redirect him on product, else render the login ui
            if(!checkIfUserIsLoggedin(product)) setCanRenderPage(true);

            //Fix login page title according product typed on previous url
            if(product) _productToRedirect = product;
            if(product) setTitle(texts.login[`${product.toLowerCase()}Title`]);
            else setTitle(texts.login.title);
            if(!checkIfUserIsLoggedin(product)) Storage.clearSession();
        }

        return () => ConfirmationCode = null;
    // eslint-disable-next-line
    }, []);

    const forgotPassHandler = () => setFormTexts('forgotPass');

    const backToLoginHandler = () => setFormTexts('login');

    const loginCall = async () => {
        if(errorStatuses.formError) updateErrorStatuses('formError', false);
        if (username.length <= 3 || password.length <= 3) return updateErrorStatuses('formError', true);

        setButtonDisabled(true);
        const res = await service.loginUser({ username, password });            // FIRST REQUEST

        if(res.status === 500 || res.error) return setPageWithTimeoutMessage();
        if(res.apiError?.status) return updateErrorStatuses('formError', true);
        
        if (res && !res.error && res.data?.hasOwnProperty('access_token')) {
            // token and user id are needed for any upcoming request
            const token = res.data.access_token;
            const userId = res.data.userId;

            if (token && userId) {
                let d = new Date();
                d.setTime(d.getTime() + (24 * 60 * 60 * 1000));

                Storage.addCookie('token', token);
                Storage.addCookie('expiredDate', d.toUTCString());
                Storage.addCookie('userId', userId);

                const userDataRequest = await service.getUserData();          // SECON REQUEST

                if (userDataRequest?.data && !userDataRequest?.error) {
                    Storage.addCookie('user', userDataRequest.data);

                    //get user Terms info and add them to cookies so all the other pages and apllications can have them
                    const userTerms = await userUtils.getUserTerms(); 
                    Storage.addCookie('userTerms', userTerms);

                    //when client redirect to login page after a product url, when client logged in, redirect him to the product url he typed.
                    if(_productToRedirect) {
                        Storage.addCookie('product', _productToRedirect);
                        return window.location.replace(Urls.BaseUrl());
                    }

                    //if user has only one product, redirect him to this product
                    if(userDataRequest?.data?.products?.length === 1) {
                        Storage.addCookie('product', userDataRequest?.data?.products[0].name);
                        return window.location.replace(Urls.BaseUrl());
                    }

                    //if user has more than one product redirect him to select product page
                    window.location.pathname = '/selectProduct';
                }
            }
        }
        else if (!res.error) {
            if (res.status && res.status !== 200) updateErrorStatuses('formError', true);
        } else updateErrorStatuses('formError', true);
    }

    const resetPassRequest = async () => {
        const res = await service.requestResetPass({username});
        if(res?.status === 500 || res?.error) return setPageWithTimeoutMessage();
        if(res?.apiError?.status === 400 && res?.apiError?.message === "Invalid email format.") return updateErrorStatuses('mail', true);
        setTitle(texts.resetPassRequest.title);
        setPageStatus('resetPassRequest');
    }

    const emailIsValid = email => {
        const re = /^(([^<>()!#$%&*+=[\]\\.,;:\s@α-ωΑ-Ω"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
        return (
            re.test(String(email).toLowerCase())
        );
    }

    const loginHandler = (e) => {
        e.preventDefault();

        updateErrorStatuses('mail', false);
        updateErrorStatuses('formError', false);

        if (pageStatus === 'forgotPass') {
            if ( !username.length || (username.length > 0 && !emailIsValid(username)) ) return updateErrorStatuses('mail', true);
            resetPassRequest();
        }
        else if (pageStatus === 'login') {
            if (username.length === 0 || password.length === 0) {
                setEmptyFields(true);
                return updateErrorStatuses('formError', true);
            } else setEmptyFields(false);
            loginCall();
        } else return;
    }

    const resetPasswordCall = async () => {
        const response = await service.resetPassword({username: ConfirmationMail, password1: pass1, password2: pass2, confirmationCode: ConfirmationCode});
        
        if (response.apiError?.status === 408) return setPageWithTimeoutMessage();

        redirectToLogin();
    }

    const resetActivateHandler = e => {
        e.preventDefault();

        if (!pass1.length) updateErrorStatuses('pass1', true);
        if (!pass2.length) updateErrorStatuses('pass2', true);
        if (!termsAccepted && pageStatus === 'userActivation') updateErrorStatuses('terms', true);
        if (!pass1.length || !pass2.length) return;
        if (pass1 !== pass2) return updateErrorStatuses('confirmPass', true);

        if(window.location.pathname.includes('activate') && !termsAccepted) return;

        setButtonDisabled(true);
        resetPasswordCall();
    }

    const resendLink = e => {
        e.preventDefault();
        service.requestNewLink({username: ConfirmationMail});
        setTitle(texts.resetPassRequest.title);
        setPageStatus('resetActivationRequest');
    }

    const newPasswordValidation = () => {
        if (!symbol1.current) return;

        if (errorStatuses.pass1 && pass1.length) updateErrorStatuses('pass1', false);
        if (errorStatuses.pass2 && pass2.length) updateErrorStatuses('pass2', false);
        if(pass1.length && (pass1.includes("/") || pass1.includes("\\")) ) updateErrorStatuses('pass1ErrorSymbol', true)
        else updateErrorStatuses('pass1ErrorSymbol', false);

        if(pass1.length && /[^A-Za-z0-9]/.test(pass1)) symbol1.current.classList.add('valid');
        else symbol1.current.classList.remove('valid');
        if(/^(?=.*[A-Za-z])/.test(pass1) && pass1.length) latin1.current.classList.add('valid');
        else latin1.current.classList.remove('valid');
        if(/\d/.test(pass1) && pass1.length) number1.current.classList.add('valid');
        else number1.current.classList.remove('valid');
        if(pass1.length > 7) chars8.current.classList.add('valid');
        else chars8.current.classList.remove('valid');

        if(!pass1.length && buttonDisabled) return setButtonDisabled(false);

        (!pass1.includes("/") && !pass1.includes("\\") && /^(?=.*[A-Za-z])/.test(pass1) && /\d/.test(pass1)
        && pass1.length > 7 ) ? checkForErrors() : setButtonDisabled(true);
    }

    const inputsKeyUp = name => {
        if (name === 'mail' || name === 'password') if (errorStatuses.formError) updateErrorStatuses('formError', false);
        if (errorStatuses[name]) updateErrorStatuses([name], false);
        if (name === 'pass2') if (errorStatuses.confirmPass) updateErrorStatuses('confirmPass', false);
    }

    const handleClickShowPassword = key => {
        setShowPass({ ...showPass, [key]: !showPass[key] });
    };
    
    const TermsText = () => (
        <span className="form-description">I agree to Deep Sea's <span onClick={() => showTermsModal(true)}>Terms & Conditions</span></span>
    );

    const GetIcon = props => (
        <>
            <GreenTick className={`green-tik ${props.class}`} />
            <GreyX className={`grey-x ${props.class}`} />
        </>
    );

    return (
        <>{canRenderPage && (<div className="login">
            {!emptyPage && <>
            <div className="login__form">
                <div className="login__form__container">
                    <div className="headline">{title}</div>
                    { pageStatus === 'expiredLink' && <>
                            {expiredLinkStatus === 'timeout'
                            ? <TimeoutLinkIcon className="marg-t-35 marg-b-30" />
                            : <ExpiredLinkIcon className="marg-t-35 marg-b-30" /> }
                            <div className="login__form__container__mailSent-text info-value">
                                {!expiredLinkStatus && texts.login.expiredMin}
                                {expiredLinkStatus === 'disabled' && texts.login.expiredMax}
                                {expiredLinkStatus === 'timeout' && window.location.href.includes('activate') && texts.login.activationTimeout}
                                {expiredLinkStatus === 'timeout' && !window.location.href.includes('activate') && texts.login.timeout}
                            </div>
                            { !expiredLinkStatus &&
                             <button type="submit" className="login__form__container__button marg-t-50 pointer" onClick={(e) => resendLink(e)}>Request new link</button>
                            }     
                        </>
                    }
                    { pageStatus !== 'resetPassRequest' && pageStatus !== 'resetActivationRequest' &&  pageStatus !== 'expiredLink' && <>
                        <div className="info-value marg-t-5 marg-b-25">{subtitle}</div>
                        { errorStatuses.formError && <div className="form-text marg-b-10 error-message">
                            <span className="marg-r-5"><ErrorTriangle /></span>
                            {emptyFields ? texts.login.emptyFields : texts.login.invalidFields}
                        </div> }
                        { (pageStatus !== 'userActivation' && pageStatus !== 'resetPass')
                        ? <form name="login">
                            { (pageStatus === 'login' || pageStatus === 'forgotPass') && <TextField onKeyUp={() => inputsKeyUp('mail')}
                                error={errorStatuses.mail} helperText={errorStatuses.mail ? textsErrors.mail : ''} autoComplete="email"
                                value={username} onChange={e => { setUsername(e.currentTarget.value) }}
                                autoFocus className="ds-input marg-t-15-i" label="*Email" name="email"
                            /> }
                            { pageStatus === 'login' && <TextField onKeyUp={() => inputsKeyUp('password')}
                                InputProps={{
                                    endAdornment: ( <InputAdornment position="end">
                                        { showPass.pass ? <PassEyeClosed className="svg-path pointer" onClick={() => handleClickShowPassword('pass')} /> 
                                        : <PassEye className="pointer" onClick={() => handleClickShowPassword('pass')} /> }
                                    </InputAdornment> )
                                }}
                                value={password} onChange={e => { setPassword(e.currentTarget.value) }} autoComplete="current-passwrod"
                                className="ds-input marg-t-15-i" label="*Password" name="password" type={showPass.pass ? "text" : "password"}
                            />}
                            { pageStatus === 'login' && 
                                <div className="login__form__container__forgotPass table-title marg-t-15 right" onClick={forgotPassHandler}>Forgot your password?</div>
                            }
                            <button type="submit" disabled={buttonDisabled} className="login__form__container__button marg-t-50 pointer" onClick={(e) => loginHandler(e)}>{buttonText}</button>
                            { pageStatus === 'forgotPass' && 
                                <div className="login__form__container__forgotPass table-title marg-t-15 center" onClick={backToLoginHandler}>Back To Login</div>
                            }
                        </form> 
                        : <form name="reset-activate">
                            <TextField
                                InputProps={{
                                    endAdornment: ( <InputAdornment position="end">
                                        { showPass.pass1 ? <PassEyeClosed className="pointer" onClick={() => handleClickShowPassword('pass1')} /> 
                                        : <PassEye className="pointer" onClick={() => handleClickShowPassword('pass1')} /> }
                                    </InputAdornment> )
                                }}
                                error={errorStatuses.pass1 || errorStatuses.pass1ErrorSymbol} helperText={errorStatuses.pass1 ? textsErrors.pass1 : '' || errorStatuses.pass1ErrorSymbol ? textsErrors.invalidChars : '' } autoComplete="new-password"
                                value={pass1} onChange={e => { setPass1(e.currentTarget.value)}} type={showPass.pass1 ? "text" : "password"} onKeyUp={newPasswordValidation}
                                autoFocus className="ds-input marg-t-15" label={`*${pageStatus === 'resetPass' ? 'New ' : ''}Password`} name="pass1"
                            />
                            <div className="marg-t-20 marg-b-15">
                                <div className="d-flex align-items-center form-description valid-texts" ref={chars8}><GetIcon class="marg-r-5" />at least 8 characters</div>
                                <div className="d-flex align-items-center form-description valid-texts" ref={latin1}><GetIcon class="marg-r-5" />1 latin letter, only latin letters are accepted</div>
                                <div className="d-flex align-items-center form-description valid-texts" ref={number1}><GetIcon class="marg-r-5" />1 number</div>
                                <div className="d-flex align-items-center form-description valid-texts" ref={symbol1}><GetIcon class="marg-r-5" />1 symbol</div>
                            </div>
                            <TextField onKeyUp={() => inputsKeyUp('pass2')}
                                InputProps={{
                                    endAdornment: ( <InputAdornment position="end">
                                        { showPass.pass2 ? <PassEyeClosed className="pointer" onClick={() => handleClickShowPassword('pass2')} /> 
                                        : <PassEye className="pointer" onClick={() => handleClickShowPassword('pass2')} /> }
                                    </InputAdornment> )
                                }}
                                error={errorStatuses.pass2 || errorStatuses.confirmPass} helperText={errorStatuses.pass2 ? textsErrors.pass2 : '' || errorStatuses.confirmPass ? textsErrors.confirmPass : ''} 
                                autoComplete="confirm-password"
                                value={pass2} onChange={e => { setPass2(e.currentTarget.value) }} type={showPass.pass2 ? "text" : "password"}
                                className="ds-input marg-t-15" label="*Confirm Password" name="pass2"
                            />
                            { pageStatus === "userActivation" && <div className="terms marg-t-15">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            name="checkedI"
                                            color="primary"
                                            checked={termsAccepted}
                                            onChange={() => setTermsAccepted(!termsAccepted)}
                                        />
                                        }
                                        label={<TermsText />}
                                    />
                                </FormGroup>
                                {errorStatuses.terms && <span className="alert-tag">{textsErrors.terms}</span>}
                            </div> }
                            <button type="submit" disabled={buttonDisabled} className="login__form__container__button marg-t-50 pointer" onClick={(e) => resetActivateHandler(e)}>{buttonText}</button>
                        </form> }
                    </> }
                    {pageStatus === 'resetPassRequest' &&  pageStatus !== 'expiredLink' && <>
                        <img alt="mailSent" src={MailSent} className="login__form__container__mailSent-img" />
                        <div className="login__form__container__mailSent-text info-value">
                            Instructions will be sent to <b>{username}</b> if it's associated with a DeepSea account.
                        </div>
                        <div className="login__form__container__mailSent-goBack form-description">
                            Inputted wrong email 
                            <span onClick={() => setPageStatus('forgotPass')}><b> click here?</b></span>
                        </div>
                    </> }
                    {pageStatus === 'resetActivationRequest' && <>
                        <img alt="mailSent" src={MailSent} className="login__form__container__mailSent-img" />
                        <div className="login__form__container__mailSent-text info-value">
                            Activation link has been sent to your email account.
                        </div>
                    </> }
                </div>
            </div>

            <div className="login__logo">
                <div className="login__logo__lineWrapper">
                    <div className="login__logo__lineWrapper__line"></div>
                </div>
                <div className="login__logo__section">
                    <Logo className="login__logo__section__logo" />
                    <div className="report-big">Data Driven Shipping</div>
                    <div className="login__logo__section__subtitles">
                        <div className="info-value">DECARBONIZATION</div>
                        <div className="info-value">TIME SAVING</div>
                        <div className="info-value">COST SAVING</div>
                        <div className="info-value">CONTROL</div>
                    </div>
                </div>
            </div>
            </>}
        </div> )}</>
    )
};

export default LoginPage;