import React, {useContext} from "react";
import { withRouter } from 'react-router-dom';
import service from "common/js/service";
import {Context as TermsContext} from 'common/store/terms';
import DropDown from "components/core/DropDown";
import {ReactComponent as LogoutIcon} from 'assets/img/app/topbar-imgs/icon-sign-out.svg';
import {ReactComponent as LogoImage} from "assets/img/app/deepsea-logo.svg";
import SessionStorage from "common/js/SessionStorage";
import { Urls } from "common/js/urls";

const DsNavbar = () => {

    const user = SessionStorage.getCookie('user')
    const {showTermsModal} = useContext(TermsContext);

    const logout = async () => {
        const response = await service.logoutUser();
        SessionStorage.clearSession();
        window.location.replace(Urls.LoginUrl());
        return response;
    }

    return (
        <React.Fragment>
            <div className="ds-navbar">
                <div className="ds-navbar__container flex-space-between">
                    <div className="ds-navbar__container__left flex-centered-start">
                        <div className="ds-navbar__logo-wrapper flex-centered-start">
                            <div className="ds-navbar__logo flex-centered">
                                <LogoImage className="svg-g-path" />
                            </div>
                         </div>
                    </div>

                    <div className="ds-navbar__container__right flex-centered-end">

                        <div className="ds-navbar__container__right__fullname headline4">
                            
                        </div>
                        <div className="ds-navbar__container__right__user-menu-dropdown">
                            <DropDown buttonLabel={`${user.lastName.charAt(0)}${user.firstName.charAt(0)}`} showCaret={false}
                                options={[
                                    {label: "Sign Out", onClick: () => logout(), className:"signout-ico", image: LogoutIcon},
                                    {label: "Terms & Conditions", onClick: () => showTermsModal(true), className:"terms"}
                                ]}
                                classNames={{button:"ds-navbar__container__right__user-menu headline3", menu:"hasArrow"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(DsNavbar);
