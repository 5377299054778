export const texts = {
    login: {
        title: 'Welcome to DeepSea',
        cassandraTitle: 'Welcome to Cassandra',
        pythiaTitle: 'Welcome to Pythia',
        subtitle: 'Enter your credentials to access your vessels!',
        button: 'Login',
        mailErrorText: 'Please write the email in the right format',
        expiredMin: 'Activation link has been expired because it was inactive for more than 24 hours. Click the button below for a new link.',
        expiredMax: 'Activation link has been expired because it was inactive for more than 30 days. Contact us for more information.',
        timeout: 'An error has occurred. Please try again later.',
        activationTimeout: 'An error has occurred and the activation account is not available right now. Please try again after some time.',
        invalidFields: `Your email or password doesn't match.`,
        emptyFields: `Enter your email and password.`,
    },
    forgotPass: {
        title: 'Forgot password?',
        subtitle: "Enter your email and we'll send you a link to get back into your account.",
        button: 'Send reset link'
    },
    resetPassRequest: {
        title: 'Check your inbox'
    },
    resetPass: {
        title: 'Reset Password',
        subtitle: "Create a new strong password for your account!",
        button: 'Reset Password',
        passErrorText: 'Enter the new password field',
        confirmPassErrorText: 'Enter the confirm password field',
        invalidCharsErrorText: 'Slashes are not allowed',
        confirmPass: "Confirm Password and New Password don't match"
    },
    userActivation: {
        title: 'Account activation',
        expiredTitle: 'Link Expired',
        timeoutTitle: 'Time Out',
        subtitle: "Create a strong password for your account!",
        terms: "Please agree to the Terms & Conditions",
        button: 'Activate'
    }
}