import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'components/Navbar/navbar';
import SessionStorage from 'common/js/SessionStorage';
import {Context as TermsContext} from 'common/store/terms';
import Product from 'components/products/product';
import { ReactComponent as CassandraImg } from '../assets/img/app/intermediate/cas.svg';
import { ReactComponent as PythiaImg } from '../assets/img/app/intermediate/pyt.svg';

const productImages = {
    "CASSANDRA": CassandraImg,
    "PYTHIA": PythiaImg
};

const texts = {
    "CASSANDRA": '360° Technical optimization',
    "PYTHIA": 'Performance routing tool'
}

const _pageTitle = 'Select the product you want to proceed';

const IntermidiatePage = () => {
    const { setTac } = useContext(TermsContext);

    const [productsList, setProductList] = useState([]);
    const [pageTitle, setPageTitle] = useState(_pageTitle);
    
    const user = SessionStorage.getCookie('user');
    const userTerms = SessionStorage.getCookie('userTerms');

    const setTerms = async () => {
        if(userTerms) setTac(userTerms);
    }

    const checkForSelectedProductWithNoAccess = () => {
        const prevSelectedProduct = SessionStorage.getCookie('product');

        //if there is product on cookies, check if user has this product else show this product disabled
        if( prevSelectedProduct && !user.products.find(product => product.name === prevSelectedProduct) ) {
            setPageTitle(`Access ${user?.products[0]?.name}`);
            setProductList([...user.products, {name: prevSelectedProduct, disabled: true}]);
        } else {
            setPageTitle(_pageTitle);
            setProductList(user.products);
        }
    }

    useEffect(() => {
        setTerms(); 
        checkForSelectedProductWithNoAccess(); 
    // eslint-disable-next-line
    }, []);

    return (
        <div className="full-width full-height flex-centered-col">
            <Navbar />
            <h2 className={`marg-b-65 custom-h2 firstCap ${pageTitle.includes('Access') ? 'lineCap' : ''}`}>{pageTitle.toLowerCase()}</h2>
            <div className="flex-centered flex-wrap">
                {productsList.map(product => (
                    <Product key={product.name} {...product} imgs={productImages} texts={texts} />
                ))}
            </div>
        </div>
    );
}
 
export default IntermidiatePage;