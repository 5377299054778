import React, {useReducer} from "react";

const reducerFunc =  (reducer, actions, initialState) => {
    const Context = React.createContext();

    const Provider = ({children}) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        const boundActions = {};
        Object.keys(actions).forEach(key => {boundActions[key] = actions[key](dispatch);});
        return (
            <Context.Provider value={{state, ...boundActions}}>
                {children}
            </Context.Provider>
        );
    };

    return {Context, Provider};
};


export default reducerFunc;