import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import {Provider as TermsProvider} from "common/store/terms";
import IntermidiatePage from 'pages/IntermidiatePage';
import TermsModal from 'components/modals/termsModal';
import TermsBanner from 'components/terms/termsBanner';
import SessionStorage from 'common/js/SessionStorage';

// pages
import LoginPage from 'pages/Login';

import "sass/index.scss";

const PrivateRoute = ({initApp, user, updateAppState, component: Component, ...rest}) => (
    <Route {...rest} render={(props) => {

        let mainProps = {
            ...props,
            user,
            updateAppState
        };

        if (props.match.params.dashboardID) {
            mainProps.dashboardID = props.match.params.dashboardID;
        }

        return <>
            {SessionStorage.isTokenValid(SessionStorage.getCookie('expiredDate')) && <>
                <TermsBanner/>
                <Component {...mainProps} />
                <TermsModal/>
            </>}
        </>
    }}/>
)

const App = () => {
  return (
    <React.Fragment>
      <Router basename='/'>
        <Switch>
          <React.Fragment>
            <Route exact path='/' render={(props) => (
              <React.Fragment>
                <LoginPage propTypes={props} />
              </React.Fragment>
            )}/>
            <Route path='/users' render={(props) => (
              <React.Fragment>
                <LoginPage propTypes={props} />
              </React.Fragment>
            )}/>
            <PrivateRoute exact path='/selectProduct' component={IntermidiatePage} />
            <TermsModal/>
          </React.Fragment>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

ReactDOM.render(
  (
    <TermsProvider>
      <App/>
    </TermsProvider>
  ),
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
