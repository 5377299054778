import CryptoJS from 'crypto-js';
import {Urls} from 'common/js/urls';
import moment from 'moment';

const SECRET_KEY = 'secret key 123Key!@#';

const SessionStorage = {

    // Check storage and cookies for param info
    get: param => {
        if (!!SessionStorage.getSessionItem(param)) {
            return SessionStorage.getSessionItem(param);
        } else {
            return SessionStorage.getCookie(param);
        }
    },

    getCookie: param => {
        let name = param + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {

            let c = ca[i];

            while (c.charAt(0) === ' ' || c.charAt(0) === '_') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                let decrypted =  CryptoJS.AES.decrypt(c.substring(name.length, c.length), SECRET_KEY).toString(CryptoJS.enc.Utf8);
                return JSON.parse(decrypted);
            }
        }
        return "";
    },

    getSessionItem: param => {
        let response = '';
        if (sessionStorage.getItem(param) !== null) {
            response = CryptoJS.AES.decrypt(sessionStorage.getItem(param).toString(), SECRET_KEY).toString(CryptoJS.enc.Utf8);
        }

        try {
            response = JSON.parse(response);
        } catch (e) {}

        return response;
    },

    save: (param, value) => {
        try {
            value = JSON.stringify(value);
        } catch (e) {}
        sessionStorage.setItem(param, CryptoJS.AES.encrypt(value, SECRET_KEY).toString());
    },

    clearStorage: () => {
        sessionStorage.clear();
    },

    clearCookies: (domain) => {
        let cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if(name.trim() === 'expiredDate') SessionStorage.addCookie('expiredDate', 'Thu, 01 Jan 1970 00:00:00');
            document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;SameSite=Lax;domain=${domain};path=/`;
        }
    },

    isTokenValid: expiredDate => {
        if(expiredDate && moment(expiredDate).isValid() && (moment(expiredDate).valueOf() > moment().valueOf())) return true;
        return false;
    },

    clearSession: () => {
        SessionStorage.clearStorage();

        if(window.location.href.includes('localhost')) return SessionStorage.clearCookies();
        if(window.location.href.includes('deepsea.ai')) return SessionStorage.clearCookies('deepsea.ai');
        SessionStorage.clearCookies('deepsea.internal');
    },

    addCookie: (param, value) => {
        let d = new Date();
        let domain = Urls.CookieUrl();
        d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
        let expires = `expires=${d.toUTCString()}`;
        document.cookie = `${param}=${CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString()};${expires};SameSite=Lax;domain=${domain};path=/`;
    }
};

export default SessionStorage;