import storeGenerator from 'common/store/storeGenerator';
import service from 'common/js/service';
import moment from "moment";

const initTerms = {data: [], date:1000000000000};

const TermsReducer = (state, {type, payload}) => {
    switch (type) {
        case "tac":
            return {...state, tac: payload};
        case "show_modal":
            return {...state, modalShow: payload};
        case "terms_response":
            return {...state, loadTerms: payload};
        case "has_received_terms":
            return {...state, hasReceivedTerms: true};
        case "set_current_terms":
            return {...state, currentTerms: payload};
        case "set_future_terms":
            return {...state, futureTerms: payload};
        case "has_new_terms":
            return {...state, hasNewTerms: payload};
        case "has_terms_server_error":
            return {...state, errorServer: payload};
        case "has_current_terms_error":
            return {...state, errorCurrent: payload};
        case "has_new_terms_error":
            return {...state, errorNew: payload};
        default:
            return state;
    }
};

const showTermsModal = (dispatch) => {
    return modalShow => dispatch({type: "show_modal", payload: modalShow});
};

const setTac = (dispatch) => {
    return tac => dispatch({type: "tac", payload: tac});
};

const setTermsError = (dispatch) => {
    return (target, error) => {
        switch (target) {
            case "server":
                return dispatch({type: "has_terms_server_error", payload: error});
            case "current":
                return dispatch({type: "has_current_terms_error", payload: error});
            case "new":
                return dispatch({type: "has_new_terms_error", payload: error});
            default: 
                return '';
        }
    }
};

const termsFlow = (dispatch) => {
    return async ({hasReceivedTerms}) => {
        // if (!hasReceivedTerms) {
            setTermsError(dispatch)('server', false);
            setTermsError(dispatch)('current', false);
            setTermsError(dispatch)('new', false);
            dispatch({type: "terms_response", payload: false});
            const response = await Promise.all([getCurrentTerms(dispatch)(), getFutureTerms(dispatch)()]);
            if (response[0] && !response[0].error) dispatch({type: "has_received_terms"});
            dispatch({type: "terms_response", payload: true});
        // }
    }
};

const getCurrentTerms = (dispatch) => {
    return async () => {
        const r = await service.getCurrentTerms();
        if(r === undefined) return;
        if (r && !r.error) {
            let data = [];
            try {
                data = JSON.parse(r.content);
            } catch (e) {
                setTermsError(dispatch)('current', 'Something went wrong!');
            }
            dispatch({type: "set_current_terms", payload: {date: moment(r.effectiveDateFrom).valueOf(), data}});
        } else {
            setTermsError(dispatch)('server', 'Αn error has occured and the content is not available right now. Please try again after some time.');
        }
        return r;
    }
};

const getFutureTerms = (dispatch) => {
    return async () => {
        const r = await service.getFutureTerms();
        if(r === undefined) return;
        if (r && !r.error) {
            let data = [];
            try {
                data = JSON.parse(r.data.content);
            } catch (e) {
                setTermsError(dispatch)('new', 'Something went wrong!');
            }
            if(r.data) {
                dispatch({type: "has_new_terms", payload: true});
                dispatch({type: "set_future_terms", payload: {date: moment(r.data.effectiveDateFrom).valueOf(), data}});
            }
        } else {
            setTermsError(dispatch)('server', 'Αn error has occured and the content is not available right now. Please try again after some time.');
        }
        return r;
    }
};

export const {Context, Provider} = storeGenerator(
    TermsReducer,
    // actions
    {
        termsFlow,
        showTermsModal,
        setTac,
    },
    // state
    {
        hasNewTerms: false,
        loadTerms: false,
        currentTerms: initTerms,
        futureTerms: initTerms,
        hasReceivedTerms: false,
        modalShow: false,
        tac: {},
        errorNew: false,
        errorCurrent: false,
        errorServer: false
    }
);