import request from "common/js/request";
import Storage from 'common/js/Storage';
import { Urls } from 'common/js/urls';

const getUrl = () => {
  return {
    baseUrl: Urls.ServicesUrl()
  }
};

const service = {
  // terms
  acceptTerms: ({ date, userId, token }) => {
    const payload = {
        acceptanceDate: date,
        accepted: true
    };
    return request.patch(`${getUrl().baseUrl}/user-management/users/${userId}/tac`, payload);
  },
  getUserTermsInfo: ({ userId, token }) => {
    return request.get(`${getUrl().baseUrl}/user-management/users/${userId}/tac`);
  },
  getCurrentTerms: () => {
    return request.get(`${getUrl().baseUrl}/deepsea-commons/terms-and-conditions/current`).then(r => r.data);
  },
  getFutureTerms: () => {
    return request.get(`${getUrl().baseUrl}/deepsea-commons/terms-and-conditions/new`);
  },
  getCurrentsTermsEffectiveDate: () => {
    return request.get(`${getUrl().baseUrl}/deepsea-commons/terms-and-conditions/current/effectiveDate`).then(r => r.data);
  },
  getFutureTermsEffectiveDate: () => {
    return request.get(`${getUrl().baseUrl}/deepsea-commons/terms-and-conditions/new/effectiveDate`).then(r => r.data);
  },

  // user
  loginUser: ({ username, password }) => {
    return request.post(
      `${getUrl().baseUrl}/user-management/users/login`,
      { username, password },
      { autoHandleError: false }
    );
  },
  logoutUser: () => {
    return request.post(`${getUrl().baseUrl}/user-management/users/logout`, { access_token: Storage.getCookie('token') });
  },
  getUserData: () => {
    return request.get(
      `${getUrl().baseUrl}/operator/user/${Storage.getCookie('userId')}`
    );
  },
  getCompanyVesselsGroups: () => {
    return request.get(
      `${getUrl().baseUrl}/operator/user/${Storage.getCookie('userId')}/company`
    )
  },
  requestResetPass: ({username}) => {
    return request.post(
      `${getUrl().baseUrl}/user-management/users/password/reset`,
      {username}
    )
  },
  codeConfirmation: ({ username, confirmationCode }) => {
    return request.get(
      `${getUrl().baseUrl}/user-management/users/${username}/confirmation/${confirmationCode}`
    );
  },
  resetPassword: ({username, password1, password2, confirmationCode}) => {
    return request.post(
      `${getUrl().baseUrl}/user-management/users/password`,
      {username, password1, password2, confirmationCode}
    )
  },
  requestNewLink: ({username}) => {
    return request.post(
      `${getUrl().baseUrl}/user-management/users/${username}/confirmation/resend`,
      {username}
    )
  },
};

export default service;
