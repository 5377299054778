import Storage from "common/js/Storage";

const isQa = window.location.href.includes('qa') && window.location.href.includes('.internal');
const isDev = window.location.href.includes('dev') && window.location.href.includes('.internal');
const isStaging = window.location.href.includes('staging') && window.location.href.includes('.internal');
const isDemo = window.location.href.includes('demo') && window.location.href.includes('deepsea.ai');
const isLocalhost = window.location.href.includes('localhost');
const isProd = (window.location.href.includes('deepsea.ai') 
                && !window.location.href.includes('qa') 
                && !window.location.href.includes('dev')
                && !window.location.href.includes('demo')
                && !window.location.href.includes('staging')
                && !window.location.href.includes('localhost')); 

export const Urls = {
    //urls for redirecting in correct login page according on the enviroment
    LoginUrl: () => {
        if(isQa) return 'https://home-qa.deepsea.internal';
        if(isDev) return 'https://home-dev.deepsea.internal';
        if(isStaging) return 'https://home-staging.deepsea.internal';
        if(isProd) return 'https://home.deepsea.ai';
        if(isDemo) return 'https://home-demo.deepsea.ai';
        if(isLocalhost) return 'http://localhost:3006/';
    },
    //urls for redirecting on the selected product
    BaseUrl: () => {
        if(isLocalhost && Storage.getCookie('product') === 'CASSANDRA') return 'http://localhost:3000';
        if(isLocalhost && Storage.getCookie('product') === 'PYTHIA') return 'http://localhost:3001';
        
        // redirect to PYTHIA
        if(isQa && Storage.getCookie('product') === 'PYTHIA') return 'https://pythia-qa.deepsea.internal';
        if(isDev && Storage.getCookie('product') === 'PYTHIA') return 'https://pythia-dev.deepsea.internal';
        if(isStaging && Storage.getCookie('product') === 'PYTHIA') return 'https://pythia-staging.deepsea.internal';
        if(isProd && Storage.getCookie('product') === 'PYTHIA') return 'https://pythia.deepsea.ai';
        if(isDemo && Storage.getCookie('product') === 'PYTHIA') return 'https://pythia-demo.deepsea.ai';

        // redirect to CASSANDRA
        if(isQa && Storage.getCookie('product') === 'CASSANDRA') return 'https://cassandra-qa.deepsea.internal';
        if(isDev && Storage.getCookie('product') === 'CASSANDRA') return 'https://cassandra-dev.deepsea.internal';
        if(isStaging && Storage.getCookie('product') === 'CASSANDRA') return 'https://cassandra-staging.deepsea.internal';
        if(isProd && Storage.getCookie('product') === 'CASSANDRA') return 'https://cassandra.deepsea.ai';
        if(isDemo && Storage.getCookie('product') === 'CASSANDRA') return 'https://cassandra-demo.deepsea.ai';        
    },
    //cookies domains
    CookieUrl: () => {
        if(isQa || isDev || isStaging) return 'deepsea.internal';
        if(isProd || isDemo) return 'deepsea.ai';
        if(isLocalhost) return '';
    },
    //url for api calls. If it is deployed on an enviroment, then the api is on same machine. We dont need a url
    ServicesUrl: () => {
        //services url when front is not in localhost
        if(!isLocalhost) return '';

        //services url when front is on localhost
        if(isLocalhost) return `https://home-dev.deepsea.internal`;
        // if(isLocalhost) return `http://10.212.134.24:12200`;
    }
}
