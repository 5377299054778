import service from "../js/service";
import Storage from "common/js/Storage";
import moment from "moment";


export const userUtils = {
    // user terms
    acceptTerms: async (userId, token) => {
        const date = moment.utc(new Date()).valueOf();
        const response = await service.acceptTerms({userId, token, date});

        //success response of this request is 204
        if(!response) {
            const userTerms = {
                accepted: true,
                acceptanceDate: date,
                effectiveDate: null
            };
            Storage.addCookie('userTerms', userTerms);
            return userTerms;
        } else if (response) {
            response.errorMessage = 'Something went wrong! Cannot accept Terms & Conditions';
        }
        return response;
    },

    activate: async ({userId, token}) => service.activateUser({userId, token}),

    getUserTermsInfo: async (userId, token) => service.getUserTermsInfo({userId, token}),

    getUserTerms: async () => {
        const user = Storage.getCookie('user');
        const userTermsInfo = await userUtils.getUserTermsInfo(user.uuid, Storage.getCookie('token'));

        if (userTermsInfo && 'error' in userTermsInfo) return userTermsInfo;

        const { data } = userTermsInfo;

        data.effectiveDate = null;

        //If user has not accept the current terms then check for future terms effectiveDate
        if (!data.accepted) {
            const futureDate = await service.getFutureTermsEffectiveDate(Storage.getCookie('token'));

            //If no future date exists then check for current terms effective date and add this as effective date
            if (!futureDate) {
                const currentDate = await service.getCurrentsTermsEffectiveDate(Storage.getCookie('token'));
                if (currentDate && ('error' in currentDate)) {
                    return currentDate;
                } else {
                    data.effectiveDate = moment(currentDate.effectiveDate).valueOf();
                }
            } else if (futureDate && 'error' in futureDate) {
                return futureDate;
            } else {
                data.effectiveDate = moment(futureDate.effectiveDate).valueOf();
            }
        //If user has accept the current terms we do not have an effective date (null)
        } else {
            data.acceptanceDate = moment(data.acceptanceDate).valueOf();
        }

        return data;
    },
}
