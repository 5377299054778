import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as DropdownTriangle} from 'assets/img/app/global-imgs/triangle.svg';
//import ExpandCollapse from "./ExpandCollapse";

export default class DropDown extends React.Component {
    static propTypes = {
        buttonLabel: PropTypes.node.isRequired,
        options: PropTypes.array,
        render: PropTypes.func,
        classNames: PropTypes.object,
        onOpen: PropTypes.func,
        showCaret: PropTypes.bool,
        hasArrow: PropTypes.bool,
        initialStyle: PropTypes.object,
    };

    static defaultProps = {
        options: [],
        render: ({closeDropDown}) => {},
        classNames: {wrapper: '', button: '', menu: ''},
        onOpen: () => {},
        showCaret: true,
        hasArrow: false,
        initialStyle: {},
    };

    constructor(props, context) {
        super(props, context);
        this.element = React.createRef();
        this.state = {
            isOpen: false
        };
    }

    componentDidMount() {
        document.body.addEventListener("click", this.clickOutsideList);
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.clickOutsideList);
    }

    clickOutsideList = (event) => {
        const domNodeCollection = [...this.element.current.getElementsByClassName('dropdown')];
        let elementClicked = false;

        for (const domNode of domNodeCollection) {
            domNode.contains(event.target) && (elementClicked = true);
        }
        !elementClicked && this.clearSelectedCategory();
    };

    clearSelectedCategory = () => {
        if (this.state.isOpen) {
            this.toggleMenu();
        }
    };

    toggleMenu = () => {
        if(!this.state.isOpen) this.props.onOpen();
        this.setState({isOpen: !this.state.isOpen});
    };

    render() {
        let options = '';
        if (this.props.options.length) {
            options = this.props.options.map(option => (
                <div className={`dropdown-my-element-wrapper ${option.classNameWrapper || ''}`} key={`${this.props.buttonLabel}-${option.label}`}>
                    <div className={`dropdown-my-element ${option.className || ''}`}
                         onClick={e => {
                             option.onClick();
                             this.toggleMenu();
                         }}>
                        {option.image && <option.image className="marg-r-10" />}
                        {option.label}
                    </div>
                </div>
            ));
        } else {
            options = this.props.render({closeDropDown: this.clearSelectedCategory});
        }

        return (
            <div ref={this.element}>
                <div className={`dropdown ${this.props.classNames.wrapper || ''}`}>
                    <button className={`btn ${this.props.classNames.button || ''}`}
                    type="button" onClick={this.toggleMenu}>
                        <span>{this.props.buttonLabel}</span>
                        {this.props.showCaret &&
                            <DropdownTriangle className={`marg-l-5 transform-04 ${this.state.isOpen ? ' rotate-right' : ''}`} />
                        }
                    </button>
                    {/*<ExpandCollapse in={this.state.isOpen} initialStyle={this.props.initialStyle} isAbstract={true} hasArrow={this.props.hasArrow}>*/}
                    {this.state.isOpen &&
                    <div className={`dropdown-my-menu ${this.props.classNames.menu || ''}`}>
                        {options}
                    </div>
                    }
                    {/*</ExpandCollapse>*/}
                </div>
            </div>
        );
    }
};